@import '../../../scss/variables';


.subactivities  {
    

    .modal-dialog {
        position: relative;
        top: 50% !important;
        transform: translateY(-50%) !important;
    }

    .modal-content {
        border-radius: 20px;

        .modal-header {
            align-items: center;
            justify-content: center;
            .btn-close {
                position: absolute;
                right: 2%;
                margin: 0;
                align-self: flex-end;
            }
        }
    }

    .modal-body {
        min-height: 100px;
        max-height: 350px;
        overflow-y: auto;
    }


    .modal-footer {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;

        .form-class {
            width: 90%;
        }

        input {
            &:focus {
                outline: none;
                border-color: rgba(51,150,184,1) ; // Promijenite ovo prema vašoj željenoj boji
                box-shadow: none;
            }
        }

        .form-group-time {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            input {
                width: 20%;
            }
        }


        .icon_style-2 {
            color: transparent;
            background: $official-gradient;
            -webkit-background-clip: text;
            background-clip: text;
            font-size: 32px;
            cursor: pointer;
            z-index: 1000;
            padding-left: 15px;
        }


    }

    
}

.subactivity {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 4px 4px 10px #dfdfdf;
    margin-bottom: 15px;
    padding: 5px;

    .subactivity-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .subactivity-info {
            width: 20%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;
            font-size: 14px;
            color: #444;
        }

        .subactivity-options {
            
            .icon-style-2 {
                color: transparent;
                background: $official-gradient;
                -webkit-background-clip: text;
                background-clip: text;

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}