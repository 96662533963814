@import '../../../scss/variables';


.side-bar {
    position: absolute;
    z-index: 500;
    background-color: #FFF;
    display: none;
    width: 15%;
    top: var(--visina-headera);
    height: calc(100vh - var(--visina-headera));
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    
    @media only screen and (max-width: 1400px) {
        width: 30%;
    }
    
    @media only screen and (max-width: 992px) {
        width: 50%;
    }
    @media only screen and (max-width: 776px) {
        height: calc(100dvh - var(--visina-headera));
     }

    .list-box {
        width: 100%;
        margin: 50px 30px;


        .list-box-element {
            display: flex;
            flex-direction: row;
            font-size: 20px;
            justify-content: flex-start;
            cursor: pointer;
            padding: 5px 0;

            .icon_style-4 {
                padding-right: 10px;
                font-size: 20px;
                margin: auto 0;
            }
        }
        span {
            font-weight: 400;
        }

    }
}

.showSideBar {
    display: flex;
    animation: showSideBar-1 1s forwards;
}

.hideSideBar {
    animation: hideSideBar-1 1s forwards;
}


@keyframes showSideBar-1 {
    0%{
        right: -15%;
    }
    100%{
        right: 0%;
        display: flex;
    }
}

@keyframes hideSideBar-1 {
    0%{
        display: flex;
        right: 0%;
    }
    100%{
        right: -15%;
        display: none;
    }
}

@media only screen and (max-width: 1400px) {
    @keyframes showSideBar-1 {
        0%{
            right: -30%;
        }
        100%{
            right: 0%;
            display: flex;
        }
    }
    
    @keyframes hideSideBar-1 {
        0%{
            display: flex;
            right: 0%;
        }
        100%{
            right: -30%;
            display: none;
        }
    }
 }

@media only screen and (max-width: 992px) {
    @keyframes showSideBar-1 {
        0%{
            right: -50%;
        }
        100%{
            right: 0%;
            display: flex;
        }
    }
    
    @keyframes hideSideBar-1 {
        0%{
            display: flex;
            right: 0%;
        }
        100%{
            right: -50%;
            display: none;
        }
    }
 }