.change-password {
    position: relative;
    margin: 50px;
    width: 85%;

    @media only screen and (max-width: 992px) {
        width: 50%;
        margin: 50px 20px;
    }

    @media only screen and (max-width: 776px) {
        width: 90%;
        margin: 50px 20px;
    }

    .form-box {
        height: 550px;
        width: 450px;
        border-radius: 30px;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;

        @media only screen and (max-width: 1400px) {
            width: 50%;
            height: 400px;
        }

        @media only screen and (max-width: 992px) {
            width: 100%;
            height: 400px;
        }

        input[type="password"],textarea,input[type="date"] {
            width: 100%;
            padding: 10px 15px;
            border-radius: 10px;
            border: 1px solid #efefef;
            font-size: 16px;
            outline: none;
            transition: border-color 0.3s;
        
            &:focus {
                outline: none;
                border-color: rgba(51,150,184,1) ; // Promijenite ovo prema vašoj željenoj boji
                box-shadow: none;
            }
        }
        

        &>div{
            width: 100%;
        }
            
        
    }

    .alert-part {
        width: 450px;
    }

    button {
        border: none;
        border-radius: 32px;
        margin: 0 auto;
    }
    .forgotten-pass {
        cursor: pointer;
        font-weight: 200;
    }
}
