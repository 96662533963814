.delete-profile {
    position: relative;
    margin: 50px;
    width: 85%;



    .alert-part {
        width: 450px;
    }

    button {
        border: none;
        border-radius: 32px;
        border: none;
        margin: 0 auto;
    }
}