@import '../../../scss/variables';


    

.aktivnosti-container {
    position: relative;
    width: 75%;
    margin: 0 auto;
    height: calc(100vh - var(--visina-headera));
    background-color: rgba($color: #fff, $alpha: .2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    @media only screen and (max-width: 1400px) {
        width: 90%;
    }

    @media only screen and (max-width: 992px) {
        height: 100dvh;
    }

    @media only screen and (max-width: 776px) {
        height: calc(100dvh - var(--visina-headera));
        }

    @media only screen and (max-width: 600px) {
        width: 95%;
        }

    .aktivnosti-wrapper {
        width: 95%;
        height: 100%;
        padding-top: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;


        @media only screen and (max-width: 1024px) {
            flex-direction: column;
            justify-content: flex-start;
        }

        .planirane-aktivnosti {
            @media only screen and (max-width: 1024px) {
                max-height: 50%;
                height: auto !important;
            }
        }

        .izvrsene-aktivnosti {
            @media only screen and (max-width: 1024px) {
                max-height: 50%;
                height: auto !important;
            }
        }

        .planirane-aktivnosti, .izvrsene-aktivnosti {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            width: 100%;

            @media only screen and (max-width: 1024px) {
                height: 50%;
            }
            
            &--content {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow-y: auto;

                @media only screen and (max-width: 1024px) {
                    overflow-y: none;
                }
            }
            
            h2{
                color: $text-light;

                @media only screen and (max-width: 1400px) {
                    font-size: 36px;
                }

                @media only screen and (max-width: 500px) {
                    font-size: 24px;
                }
            }
        }

        
        
    }


    button {
        border: none;
        border-radius: 32px;
    }
}



.aktivnost {
    position: relative;
    width: 90%;
    padding: 10px 20px;
    background-color: rgba($color: #FFF, $alpha: 1);
    border-radius: 10px;
    margin: 10px 0;
    z-index: 100;
    border: 2px solid transparent;

    @media only screen and (max-width: 600px) {
        width: 100%;
     }

    @media only screen and (max-width: 500px) {
        padding: 10px;
    }

    &.priority-border {
        border: 2px solid #CE4563; 
    }
    

    .aktivnost-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;  

        @media only screen and (max-width: 1400px) {
            font-size: 24px;
        }

        @media only screen and (max-width: 500px) {
            padding-bottom: 5px;
        }

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 85%;

            h3 {
                padding-right: 10px;
                font-size: 26px;

                @media only screen and (max-width: 500px) {
                    font-size: 18px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .aktivnost-body {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;      
        
        @media only screen and (max-width: 500px) {
            padding-top: 5px;
        }
    }

    .icon_style-2 {
        color: transparent;
        background: $official-gradient;
        -webkit-background-clip: text;
        background-clip: text;
        font-size: 20px;
        cursor: pointer;
        z-index: 1000;
        padding-left: 15px;

        @media only screen and (max-width: 500px) {
            font-size: 18px;
        }
    }

    .checkbox-rules {
        position: relative;
        z-index: 1000;
    }

}


.nova-aktivnost {
    position: absolute;
    top: -500px;
    background-color: rgba($color: #FFF, $alpha: 1);
    width: 50%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 500px;
    z-index: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @media only screen and (max-width: 1400px) {
        width: 45%;
        top: -400px;
        height: 400px;
    }

    @media only screen and (max-width: 992px) {
        width: 75%;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
    }

    
    .form-box {
        width: 80%;
        height: 80%;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        @media only screen and (max-width: 1400px) {
            height: 100%;
            width: 90%;
            margin-top: 10px;
        }

        &>div {
            width: 80%;
        }

        .btn-dodaj-aktivnost {
            margin-top: 30px;

            @media only screen and (max-width: 1400px) {
                margin-top: 0px;
            }
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .icon_style-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-dark;
        font-size: 50px;
        width: 160px;
        height: 100px;

        @media only screen and (max-width: 600px) {
            height: 75px;
            font-size: 40px;
        }
    }

    input[type="text"],textarea,input[type="date"] {
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
        border: 1px solid #efefef;
        font-size: 16px;
        outline: none;
        transition: border-color 0.3s;
    
        &:focus {
            outline: none;
            border-color: rgba(51,150,184,1) ; // Promijenite ovo prema vašoj željenoj boji
            box-shadow: none;
        }
    }

    .create-activity {
        position: absolute;
        top: 500px;
        width: 25%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        justify-content: center;
        background-color: #FFF;

        @media only screen and (max-width: 1400px) {
            width: 35%;
            top: 400px;
        }

        @media only screen and (max-width: 992px) {
            width: 25%;
        }

        @media only screen and (max-width: 600px) {
            width: 40%;
            height: 75px;
        }
    }
    


}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.0); // Poluprovidna crna boja, možete prilagoditi po potrebi
    z-index: 30; // Postavite visok z-index da prekrije ostale elemente
}
.moveDown {
    animation: slideDown 0.5s forwards;
}

.moveUp {
    animation: slideUp 0.5s forwards;
}


.izmeni-aktivnost {
    .modal-dialog {
        position: relative;
        height: 80%;
        top: 50% !important;
        transform: translateY(-50%) !important;
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        border-radius: 20px;

        .modal-header {
            border: none;
        }

        .modal-body {
            width: 90%;
            .form-class{
                width: 100%;
                height: 100%;
                .modal-form {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 100%;
                }
            }
        }

        .modal-footer {
            border: none;
            height: 30%;

            button {
                border: none;
                border-radius: 32px;
                width: 200px;
                height: 64px;
            }
        }

        .priority {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .priority-label {
                padding-bottom: 5px;
            }
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 22px;
          }
          
          .switch input { 
            opacity: 0;
            width: 0;
            height: 0;
          }
          
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #FFF;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          .slider:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 4px;
            bottom: 4px;
            background-color: #FFF;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          input:checked + .slider {
            background-color: #FFF;
          }
          
          input:focus + .slider {
            box-shadow: 0 0 1px #FFF;
          }
          
          input:checked + .slider:before {
            -webkit-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px);
          }
          
          /* Zaokruženi slajderi */
          .slider.round {
            border-radius: 34px;
            background: linear-gradient(270deg, rgba(51,150,184,1) 0%, rgba(51,150,184,1) 20%, rgba(148,0,106,1) 50%, rgba(242,112,96,1) 100%);
          }
          
          .slider.round:before {
            border-radius: 50%;
          }
    }


    input[type="text"],textarea,input[type="date"] {
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
        border: 1px solid #efefef;
        font-size: 16px;
        outline: none;
        transition: border-color 0.3s;
    
        &:focus {
            outline: none;
            border-color: rgba(51,150,184,1) ; // Promijenite ovo prema vašoj željenoj boji
            box-shadow: none;
        }
    }
}

@keyframes slideDown {
    from {
        top: -500px;
    }
    to {
        top: 0;
    }
}

@keyframes slideUp {
    from {
        top: 0;
    }
    to {
        top: -500px;
    }
}

@media only screen and (max-width: 1400px) {
    @keyframes slideDown {
        from {
            top: -400px;
        }
        to {
            top: 0;
        }
    }
    
    @keyframes slideUp {
        from {
            top: 0;
        }
        to {
            top: -400px;
        }
    }
}