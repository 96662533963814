@import '../../scss/variables';

.signup{
    height: 100dvh;
    background-color: #000;
    background-image: url(../../assets/covers/hero-4.jpg);
    color: $text-dark;

    .signup-container {
        position: relative;
        height: calc(100vh - var(--visina-headera));
        background-color: rgba($color: #000000, $alpha: .2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        @media only screen and (max-width: 776px) {
            height: calc(100dvh - var(--visina-headera));
         }

        .form-box {
            height: 550px;
            width: 450px;
            background-color: rgba($color: #FFF, $alpha: 1);
            border-radius: 30px;
            transition: all .75s;

            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.5);

            @media only screen and (max-width: 776px) {
                width: 90%;
             }

            input[type="text"], [type="email"], [type="password"],textarea,input[type="date"] {
                width: 100%;
                padding: 10px 15px;
                border-radius: 10px;
                border: 1px solid #efefef;
                font-size: 16px;
                outline: none;
                transition: border-color 0.3s;
            
                &:focus {
                    outline: none;
                    border-color: rgba(51,150,184,1) ; // Promijenite ovo prema vašoj željenoj boji
                    box-shadow: none;
                }
            }
            

            &>div{
                width: 75%;

                @media only screen and (max-width: 776px) {
                    width: 90%;
                 }
            }
                
            

            &:hover {
                transform: scale(1.05);
                box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
            }
        }

        button {
            border: none;
            border-radius: 32px;
        }
    }
}