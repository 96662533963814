.faq-container {
    width: 85%;
    margin: 50px;

    @media only screen and (max-width: 992px) {
        width: 50%;
        margin: 50px 20px;
    }
    @media only screen and (max-width: 776px) {
        width: 90%;
        margin: 50px 20px;
    }

    .accordion {
        width: 50%;

        @media only screen and (max-width: 1200px) {
            width: 70%;
        }
        @media only screen and (max-width: 992px) {
            width: 100%;
        }

        .accordion-item {
            border: none;
            padding-bottom: 10px;
            .accordion-header {
                button {
                    border-radius: 20px;
                    border: 1px solid ;

                    &.collapsed {
                        border-radius: 20px;
                    }

                    &, &:focus {
                        outline: none;
                        border-color: rgba(51,150,184,1) ; // Promijenite ovo prema vašoj željenoj boji
                        box-shadow: none;
                        background-color: transparent;
                    }
                }
                
            }
        }
    }
}