@import '../../scss/variables';

.settings-page {
    height: 100dvh;
    background-color: #FFF;
    color: $text-dark;

    .settings-page-container {
        position: relative;
        height: calc(100vh - var(--visina-headera));
        background-repeat: no-repeat;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @media only screen and (max-width: 776px) {
            flex-direction: column;
        }
        @media only screen and (max-width: 776px) {
            height: calc(100dvh - var(--visina-headera));
         }
    }
}