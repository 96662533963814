@import '../../scss/variables';

.aktivnosti {
    height: 100dvh;
    background-color: #000;
    background-image: url(../../assets/covers/hero-4.jpg);
    color: $text-dark;

    
    @media only screen and (max-width: 992px) {
        overflow-y: hidden;
    }
}