@import '../../../../scss/variables';

.burger-box {
    position: relative;
    width: 100%;
    height: 35px;
    background-color:#fff;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    @media only screen and (max-width: 776px) {
        height: 25px;
     }

    .line-1, .line-2, .line-3 {
        position: absolute;
        width: 50px;
        height: 4px;
        border-radius: 5px;
        background: linear-gradient(270deg, rgb(51, 150, 184) 0%, rgb(51, 150, 184) 20%, rgb(148, 0, 106) 50%, rgb(242, 112, 96) 100%);

        @media only screen and (max-width: 776px) {
            width: 40px;
            height: 3px;
         }
    }

    .line-1 {
        top: 0;
    }

    .line-2 {
        top: 50%;
    }

    .line-3 {
        top: 100%;
    }

    .change-to-close-1 {
        top: 50%;
        animation: changeToCloseBtn-1 .5s forwards;
    }
    .change-to-close-3 {
        top: 50%;
        animation: changeToCloseBtn-3 .5s forwards;
    }
    .change-to-close-2 {
        animation: changeToCloseBtn-2 .5s forwards;
    }

    .change-to-normal-1 {
        top: 0%;
        animation: changeToNormalBtn-1 .5s forwards;
    }
    .change-to-normal-3 {
        top: 100%;
        animation: changeToNormalBtn-3 .5s forwards;
    }
    .change-to-normal-2 {
        animation: changeToNormalBtn-2 .5s forwards;
        
    }
    
}

/********************************** CLOSE **************************************/

@keyframes changeToCloseBtn-1 {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(135deg);
    }
}

@keyframes changeToCloseBtn-3 {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(-135deg);
    }
}

@keyframes changeToCloseBtn-2 {
    0%{
        display: block;
        left: 50%;
        transform: translateX(-50%);
        opacity: .5;
    }
    100%{
        left: 0%;
        display: none;
        opacity: 0;
    }
}
/********************************** NORMALE **************************************/
@keyframes changeToNormalBtn-1 {
    0%{
        transform: rotate(135deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes changeToNormalBtn-3 {
    0%{
        transform: rotate(135deg);
    }
    100%{
        transform: rotate(0);
    }
}

@keyframes changeToNormalBtn-2 {
    0%{
        display: block;
        left: 0%;
        opacity: .5;
    }
    100%{
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;
    }
}