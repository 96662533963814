@import '../../../scss/variables';

.left-side-bar {
    position: relative;
    width: 15%;
    height: 100%;
    padding: 40px 30px;
    border-right: 1px solid #efefef;

    @media only screen and (max-width: 1600px) {
        width: 20%;
    }
    @media only screen and (max-width: 1400px) {
        width: 25%;
    }
    @media only screen and (max-width: 1200px) {
        width: 30%;
    }
    @media only screen and (max-width: 992px) {
        width: 40%;
    }
    @media only screen and (max-width: 776px) {
        width: 100%;
        height: auto;
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #efefef;
    }


    .list-box {
        margin: 10px 0px;

        @media only screen and (max-width: 776px) {
             margin: 10px 0px;
        }

        .labels {
            @media only screen and (max-width: 776px) {
                font-size: 12px;
            }
        }
        .list-box-container {
            @media only screen and (max-width: 776px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
            .list-box-element {
                display: flex;
                flex-direction: row;
                font-size: 20px;
                justify-content: flex-start;
                cursor: pointer;
    
                @media only screen and (max-width: 1200px) {
                    padding: 5px 10px;
                }
    
                
    
                .icon_style-4 {
                    padding-right: 10px;
                    font-size: 20px;
                    margin: auto 0;
                    @media only screen and (max-width: 776px) {
                        font-size: 16px;
                    }
                }
            }
        }

        
        span {
            font-weight: 400;
            @media only screen and (max-width: 776px) {
                font-size: 14px;
            }
        }

    }
}