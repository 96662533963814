.dnd_modal {
    
    .modal-content {
        border-radius: 20px;

        .modal-header {
            align-items: center;
            justify-content: center;
            .btn-close {
                position: absolute;
                right: 2%;
                margin: 0;
                align-self: flex-end;
            }
        }

        .modal-body {

            .aktivnost {
                width: 100%;
                padding: 5px 20px;
                box-shadow: 4px 4px 10px #dfdfdf;
                .aktivnost-title {
                    h3 {
                        font-size: 20px;
                    }
                }
            }

            max-height: 350px;
            overflow-y: auto;
        }
    
        .modal-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

}

