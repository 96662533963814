@import '../../../scss/variables';

.desktop-nav {
    position: relative;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: rgba($color: #FFF, $alpha: 1);
    color: #101e1f;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    z-index: 10000;


    .btnBurger-regular {
        height: 100%;
        width: 15%;
    }
        .logoContent {
            width: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .logoHeader {
                height: 82px;
            }
        }
        

        .navTitle {
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;

            h6 {
                a, a:hover, a:active {
                    text-decoration: none;
                    background: linear-gradient(270deg, rgba(51,150,184,1) 0%, rgba(51,150,184,1) 20%, rgba(148,0,106,1) 50%, rgba(242,112,96,1) 100%);
                    color: transparent; // Skrivamo pravu boju teksta
                    text-decoration: none;
                
                    // Ovo je ključni deo: koristimo background-clip svojstvo da bi gradient bio vidljiv samo na tekstu
                    -webkit-background-clip: text;
                    background-clip: text;
                }

            }
        }
}

.mobile-nav {
    position: relative;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: rgba($color: #FFF, $alpha: 1);
    color: #101e1f;
    padding: 0 20px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    z-index: 1000;

    .btnBurger-regular {
        height: 100%;
        width: 15%;
    }

        .logoContent {
            width: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            .logoHeader {
                height: 82px;

                @media only screen and (max-width: 776px) {
                    width: 90%;
                 }
            }
        }
        

        .navTitle {
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;

            @media only screen and (max-width: 776px) {
                h1 {
                    font-size: 24px;
                }
             }

             @media only screen and (max-width: 400px) {
                h1 {
                    font-size: 18px;
                }
             }

            h6 {
                a, a:hover, a:active {
                    text-decoration: none;
                    background: linear-gradient(270deg, rgba(51,150,184,1) 0%, rgba(51,150,184,1) 20%, rgba(148,0,106,1) 50%, rgba(242,112,96,1) 100%);
                    color: transparent; // Skrivamo pravu boju teksta
                    text-decoration: none;
                
                    // Ovo je ključni deo: koristimo background-clip svojstvo da bi gradient bio vidljiv samo na tekstu
                    -webkit-background-clip: text;
                    background-clip: text;
                }

            }
        }
}