@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './scss/variables';

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 400px) {
  p, span, a {
      font-size: 14px;
  }
}


.btn-prim {
  border-radius: 32px;
  width: 200px;
  height: 64px;
  color: $text-light;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(270deg, rgba(51,150,184,1) 0%, rgba(51,150,184,1) 20%, rgba(148,0,106,1) 50%, rgba(242,112,96,1) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
  transition: all .3s;

  &:active {
      box-shadow: none;
      transform: scale(.99);
  }

  @media only screen and (max-width: 1200px) {
    width: 180px;
    height: 48px; 
  }
}



