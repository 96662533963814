@import '../../scss/variables';

.welcome-page {
    height: 100dvh;
    background-color: #FFF;
    background-image: url(../../assets/covers/hero-4.jpg);
    color: $text-dark;

    

    .welcome-page-container {
        position: relative;
        height: calc(100vh - var(--visina-headera));
        background-color: rgba($color: #000000, $alpha: .2);
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        @media only screen and (max-width: 776px) {
            height: calc(100dvh - var(--visina-headera));
         }

        .form-box {
            height: 375px;
            width: 500px;
            background-color: rgba($color: #FFF, $alpha: 1);
            border-radius: 30px;
            transition: all .5s;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.75);

            @media only screen and (max-width: 776px) {
               width: 80%;
            }


            form {
                height: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;

            }

            &:hover {
                transform: scale(1.05);
                box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
            }
        }
    }
}
